<template>
      <b-tabs
      position="is-centered"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Standard transformation" selected="true">
            <div class="box">
              <div class="content">
                <div class="has-text-centered">
                  <span class="title-help" id="log">Logarithm transformation</span>
                </div>
              </div>
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/normalization/bio-logarithm.png"/>
                <div class="content" style="padding-top: 570px;">
                  <img class="couche2" src="@/assets/img/help/normalization/logarithm-transformation.gif"/><br>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    Logarithm transformation aims at <strong>making variable
                    distributions closer to the Gaussian distribution</strong>.
                    This is a requirement for parametric tests (ANOVA, Student,
                    …) and it is advised for exploratory analysis. In
                    particular, it is usual to <strong>use these transformations
                    on microarray data for all subsequent analyses or on TMM /
                    TMMwsp normalized RNA-seq data for subsequent exploratory
                    analyses</strong> (PCA, clustering, …).
                    <br><br>
                    On the before/after boxplot, check that the variable
                    distributions are symmetric, with only a few outliers.
                  </b-message>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/attention-actif.png"/>
                    For <strong>RNA-seq</strong> data, check the corresponding menu that provides
                    an option to combine normalization and log-transformation.
                  </b-message>
                </div>
              </div>
              <br><br>
              <div class="content">
                <div class="has-text-centered">
                  <span class="title-help" id="log">Scaling</span>
                </div>
              </div>
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/normalization/bio-scaling.png"/>
                <div class="content" style="padding-top: 600px;">
                  <img class="couche2" src="@/assets/img/help/normalization/scaling.gif" style="padding-top: 30px;"/><br>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    Centering and scaling are useful to obtain <strong>comparable
                    scales</strong> for variables that are not on the same scale.
                    It is also performed by default in most exploratory analyses
                    (clustering, PCA, …) without the need to use the transformed
                    dataset.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Quantile normalization" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/normalization/bio-quantile-normalization.png"/>
                <div class="content" style="padding-top: 580px;">
                  <div class="has-text-centered">
                    <img class="couche2" src="@/assets/img/help/normalization/quantile-normalization.gif" style="padding-top: 10px;"/><br>
                  </div>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    Quantile normalization aims at <strong>aligning the distributions
                    of all individuals</strong> (over all variable values). This type
                    of normalization is usually advised to correct technical biases
                    in some experiments that produce a large number of continuous
                    measurements (like <strong>microarray data</strong>, after they
                    have been log2-transformed, for instance). Check that all distribution
                    plots are well aligned after quantile normalization.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="RNA-seq (and other count) data" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/normalization/bio-rna-seq.png"/>
                <div class="content" style="padding-top: 600px;">
                  <img class="couche2" src="@/assets/img/help/normalization/rna-seq.gif" style="padding-top: 30px;"/><br>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    Two different types of normalization are implemented:
                    <strong>TMM</strong> (which is advised in most cases) and
                    <strong>TMMwsp</strong>, both as implemented in the R
                    package <u>edgeR</u>).<br>
                    These normalizations aim at correcting the sequencing depth
                    bias and making counts comparable between individuals.
                    TMMwsp is to be preferred over TMM <strong>when the number
                    of zeros in your data is large</strong>.<br>
                    Check that the boxplots are similar after normalization.
                  </b-message>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/attention-actif.png"/>
                    These normalizations are designed for count data. <strong>If
                    your RNA-seq data are already normalized</strong>, you must
                    not use it (and the results of your tests might be incorrect
                    because tests for count data use both raw and normalized
                    data).
                    <br><br>
                    <strong>If your data are already log-transformed</strong>,
                    you can undo this log-transformation and perform the TMM
                    or TMMwsp normalization. Log-transformed RNA-seq counts have
                    to be used for exploratory analyses (PCA, clustering, …) but
                    should not be used for tests.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Compositional data" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/normalization/bio-compositional-data.png"/>
                <div class="content" style="padding-top: 585px;">
                  <div class="has-text-centered">
                    <img class="couche2" src="@/assets/img/help/normalization/compositional.gif" style="padding-top: 50px;"/><br>
                  </div>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    Compositional data are data for which the sum of all variable
                    values for a given individual is always the same (e.g.,
                    percentages are compositional data because they sum to 1). Such
                    data are not adequate for a direct use in statistical analyses.
                    We propose <strong>different types of transformations</strong>
                    that correct this problem. Barplots are used to represent the
                    value of each variable in each individual. It is expected that
                    bars corresponding to individuals have varying heights after the
                    transformation.
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Metagenomic data" selected="true">
            <div class="box">
              <div class="content">
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/loupe.png"/>
                  Metagenomic data <strong>can be considered as count
                  data</strong> (like RNA-seq) <strong>or as compositional
                  data</strong>. Both situations are handled with different
                  normalization methods (similar to the ones described in the
                  corresponding tabs).
                </b-message>
                <b-message type="is-info" >
                  <img src="@/assets/img/help/general/attention-actif.png"/>
                  When considering metagenomic data as count data,
                  <strong>TMMwsp normalization might be a better choice</strong>
                  than TMM because it accounts for the excess of zeros that
                  metagenomic data usually have.<br><br>
                  Be careful to check if your metagenomic data are already
                  log-transformed or not before you choose a normalization.
                </b-message>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="Correcting batch effect with ComBat" selected="true">
            <div class="box">
              <div class="multicouche">
                <img class="bulle couche1" src="@/assets/img/help/normalization/bio-correcting-batch.png"/>
                <div class="content" style="padding-top: 570px;">
                  <img class="couche2" src="@/assets/img/help/normalization/correcting-batch.gif"/><br>
                  <b-message type="is-info" >
                    <img src="@/assets/img/help/general/loupe.png"/>
                    ComBat approach is the approach implemented in the R package
                    <u>sva</u> and is designed to correct explicit batch effect
                    (described by a factor that must be provided with the
                    dataset to normalize). It can be applied to continuous data
                    (like microarray data) or to count data (in the latter case,
                    it automatically includes TMM as a prior step).
                  </b-message>
                </div>
              </div>
            </div>
          </b-tab-item>
      </b-tabs>
</template>
<script>
export default {
  name: 'FullRNormalizationStep'
}
</script>
